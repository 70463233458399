import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID!,
        authority: 'https://login.microsoftonline.com/c44faee9-8cbc-4913-8e88-f27b92e1c3e4',
        redirectUri: '/',
        postLogoutRedirectUri: '/'
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const acquireAccessTokenSilent = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        // User is not signed in. Throw error or wait for user to login.
        // Do not attempt to log a user in outside of the context of MsalProvider
        throw new Error('User is not authenticated!');
    }

    const request = {
        scopes: [process.env.REACT_APP_API_SCOPE!],
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};
