import { SnackbarOrigin } from '@mui/material';
import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { VariantType } from 'notistack';

export type ConfirmDialogModel = {
    okAction: Action | ThunkAction<any, any, any, any>;
    cancelAction?: Action;
    dialogMessage: string;
    primaryActionName: string;
    dialogTitle: string;
};

export type AlertDialogModel = {
    dialogMessage: string;
    dialogTitle: string;
    icon: 'info' | 'warning' | 'error';
};

export type ShowSnackbarModel = {
    id?: string;
    message: string;
    autoHideDuration?: number;
    severity: VariantType;
    position?: SnackbarOrigin;
    persist?: boolean;
};

type SnackbarModel = ShowSnackbarModel & {
    id: string;
    isOpen: boolean;
};

type UiState = {
    snackbars: { [id: string]: SnackbarModel };
    dialogs: {
        alert: AlertDialogModel | null;
        confirm: ConfirmDialogModel | null;
    };
};

const snackbarsSlice = createSlice({
    name: 'snackbars',
    initialState: {
        snackbars: {},
        dialogs: {
            alert: null,
            confirm: null
        }
    } as UiState,
    reducers: {
        enqueueSnackbar: (state, action: PayloadAction<ShowSnackbarModel>) => {
            const { payload } = action;

            const snackbar: SnackbarModel = {
                ...payload,
                isOpen: true,
                id: payload.id ?? uniqueId()
            };
            state.snackbars[snackbar.id] = snackbar;
        },
        closeSnackbar: (state, action: PayloadAction<string>) => {
            state.snackbars[action.payload].isOpen = false;
        },
        removeSnackbar: (state, action: PayloadAction<string>) => {
            delete state.snackbars[action.payload];
        },
        showConfirmDialog: (state, action: PayloadAction<ConfirmDialogModel>) => {
            state.dialogs.confirm = action.payload;
        },
        hideConfirmDialog: state => {
            state.dialogs.confirm = null;
        },
        showAlertDialog: (state, action: PayloadAction<AlertDialogModel>) => {
            state.dialogs.alert = action.payload;
        },
        hideAlertDialog: state => {
            state.dialogs.alert = null;
        }
    }
});

export const {
    enqueueSnackbar,
    closeSnackbar,
    removeSnackbar,
    showConfirmDialog,
    hideConfirmDialog,
    showAlertDialog,
    hideAlertDialog
} = snackbarsSlice.actions;

export default snackbarsSlice.reducer;
