import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export type VoucherFilters = {
    id: string;
    division: string;
    text: string;
    accountDate: string;
    createdBy: string;
    createdDate: string;
    approver: string;
    voucherNo: string;
};
type VoucherState = {
    createVoucherDialog: {
        isOpen: boolean;
    };
    importVoucherDialog: {
        isOpen: boolean;
    };
    selectedDivision: string;
    isLinesDirty: boolean;
    searchState: {
        status: number;
        yearAndMonth: string | null;
        filters: VoucherFilters;
    };
    shareVoucherDialog: {
        isOpen: boolean;
        voucherId: number | null;
    };
    copyVoucherDialog: {
        isOpen: boolean;
        voucherId: number | null;
    };
    hasVoucherLineErrors: boolean;
};

const voucherSlice = createSlice({
    name: 'vouchers',
    initialState: {
        createVoucherDialog: {
            isOpen: false
        },
        importVoucherDialog: {
            isOpen: false
        },
        searchState: {
            status: 1,
            yearAndMonth: null,
            filters: {
                division: '',
                text: '',
                accountDate: '',
                createdBy: '',
                createdDate: '',
                approver: '',
                voucherNo: ''
            }
        },
        shareVoucherDialog: {
            isOpen: false,
            voucherId: null
        },
        copyVoucherDialog: {
            isOpen: false,
            voucherId: null
        },
        hasVoucherLineErrors: false
    } as VoucherState,
    reducers: {
        openCreateVoucherDialog: (state, action: PayloadAction<void>) => {
            state.createVoucherDialog.isOpen = true;
        },
        closeCreateVoucherDialog: state => {
            state.createVoucherDialog.isOpen = false;
        },
        openImportVoucherDialog: (state, action: PayloadAction<void>) => {
            state.importVoucherDialog.isOpen = true;
        },
        closeImportVoucherDialog: state => {
            state.importVoucherDialog.isOpen = false;
        },
        setSelectedDivision: (state, action: PayloadAction<string>) => {
            state.selectedDivision = action.payload;
        },
        setLinesDirty: (state, action: PayloadAction<boolean>) => {
            state.isLinesDirty = action.payload;
        },
        setSearchState: (
            state,
            action: PayloadAction<{
                status: number;
                yearAndMonth: string | null;
                pagesize: number;
                page: number;
                filters: VoucherFilters;
            }>
        ) => {
            state.searchState = action.payload;
        },
        openShareVoucherDialog: (state, action: PayloadAction<number>) => {
            state.shareVoucherDialog.isOpen = true;
            state.shareVoucherDialog.voucherId = action.payload;
        },
        closeShareVoucherDialog: state => {
            state.shareVoucherDialog.voucherId = null;
            state.shareVoucherDialog.isOpen = false;
        },
        openCopyVoucherDialog: (state, action: PayloadAction<number>) => {
            state.copyVoucherDialog.isOpen = true;
            state.copyVoucherDialog.voucherId = action.payload;
        },
        closeCopyVoucherDialog: state => {
            state.copyVoucherDialog.isOpen = false;
            state.copyVoucherDialog.voucherId = null;
        },
        setVoucherLineErrors: (state, action: PayloadAction<boolean>) => {
            state.hasVoucherLineErrors = action.payload;
        }
    }
});

export const {
    openCreateVoucherDialog,
    closeCreateVoucherDialog,
    setSelectedDivision,
    setLinesDirty,
    openImportVoucherDialog,
    closeImportVoucherDialog,
    setSearchState,
    openShareVoucherDialog,
    closeShareVoucherDialog,
    openCopyVoucherDialog,
    closeCopyVoucherDialog,
    setVoucherLineErrors
} = voucherSlice.actions;
export default voucherSlice.reducer;
