import { AuthenticationResult, EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { setLocale } from 'yup';
import { msalInstance } from './common/auth/auth';
import { userAuthenticationChanged } from './features/user/redux/userSlice';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

setLocale({
    mixed: {
        notType: ({ type, label }) => {
            switch (type) {
                case 'number':
                    return `${label} is not a number`;
                case 'string':
                    return `${label} is not a string`;
                default:
                    return `${label} is invalid`;
            }
        }
    }
});

const getRolesFromAccessToken = (accessToken: string) => {
    const token_parts = accessToken.split('.');
    const payloadStr = window.atob(token_parts[1]);
    const payload = JSON.parse(payloadStr);
    return payload.roles ?? [];
};

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const payload = event.payload as AuthenticationResult;
        // Roles are not available directly in the payload response object,
        // but they are available in the access token. So lets decode and
        // retrieve the roles from the access token.
        // https://stackoverflow.com/questions/64658682/how-to-retrieve-user-roles-from-azule-ad-msal-accesstoken
        const roles = getRolesFromAccessToken(payload.accessToken);
        store.dispatch(
            userAuthenticationChanged({
                account: payload.account,
                roles: roles
            })
        );
    }
});

// Importing the app asynchronously since setLocale
// must be set before the app is imported.
async function render() {
    const { App } = await import('./App');
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    errorComponent={() => <p>Authentication failed.</p>}
                    loadingComponent={() => <p>Logging in...</p>}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <IntlProvider locale={navigator.language}>
                            <Provider store={store}>
                                <SnackbarProvider maxSnack={4}>
                                    <App />
                                </SnackbarProvider>
                            </Provider>
                        </IntlProvider>
                    </LocalizationProvider>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}

render();
