import type { TypedStartListening } from '@reduxjs/toolkit';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from '.';

export const listenerMiddleware = createListenerMiddleware();

export type AddListeners = (startAppListening: TypedStartListening<RootState, AppDispatch>) => void;

// Check example app repo for pattern on how to add listeners
// Example:
// addProductListeners(listenerMiddleware.startListening as TypedStartListening<RootState, AppDispatch>);
