import { emptyApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        userSettingsGetAll: build.query<UserSettingsGetAllApiResponse, UserSettingsGetAllApiArg>({
            query: () => ({ url: `/api/UserSettings/all` })
        }),
        userSettingsGet: build.query<UserSettingsGetApiResponse, UserSettingsGetApiArg>({
            query: () => ({ url: `/api/UserSettings` })
        }),
        userSettingsPut: build.mutation<UserSettingsPutApiResponse, UserSettingsPutApiArg>({
            query: queryArg => ({
                url: `/api/UserSettings/${queryArg.username}`,
                method: 'PUT',
                body: queryArg.userSettingsDto
            })
        }),
        userGetAll: build.query<UserGetAllApiResponse, UserGetAllApiArg>({
            query: () => ({ url: `/api/users` })
        }),
        userGetProfile: build.query<UserGetProfileApiResponse, UserGetProfileApiArg>({
            query: () => ({ url: `/api/profile/picture` })
        }),
        organizationLevelGetAll: build.query<
            OrganizationLevelGetAllApiResponse,
            OrganizationLevelGetAllApiArg
        >({
            query: () => ({ url: `/api/OrganizationLevel/all` })
        }),
        organizationLevelGet: build.query<OrganizationLevelGetApiResponse, OrganizationLevelGetApiArg>({
            query: queryArg => ({ url: `/api/OrganizationLevel`, params: { email: queryArg.email } })
        }),
        organizationLevelUpdate: build.mutation<
            OrganizationLevelUpdateApiResponse,
            OrganizationLevelUpdateApiArg
        >({
            query: queryArg => ({
                url: `/api/OrganizationLevel`,
                method: 'POST',
                params: { email: queryArg.email, organizationLevel: queryArg.organizationLevel }
            })
        }),
        organizationLevelDelete: build.mutation<
            OrganizationLevelDeleteApiResponse,
            OrganizationLevelDeleteApiArg
        >({
            query: queryArg => ({
                url: `/api/OrganizationLevel`,
                method: 'DELETE',
                params: { email: queryArg.email }
            })
        }),
        settingsGetAppSettings: build.query<SettingsGetAppSettingsApiResponse, SettingsGetAppSettingsApiArg>({
            query: () => ({ url: `/api/settings` })
        }),
        recipientsGetEligebleRecipients: build.query<
            RecipientsGetEligebleRecipientsApiResponse,
            RecipientsGetEligebleRecipientsApiArg
        >({
            query: () => ({ url: `/api/Recipients` })
        }),
        manualVoucherCopyVoucher: build.mutation<
            ManualVoucherCopyVoucherApiResponse,
            ManualVoucherCopyVoucherApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucher/copy`,
                method: 'POST',
                body: queryArg.copyVoucherRequest
            })
        }),
        manualVoucherShare: build.mutation<ManualVoucherShareApiResponse, ManualVoucherShareApiArg>({
            query: queryArg => ({
                url: `/api/ManualVoucher/share`,
                method: 'POST',
                body: queryArg.shareVoucherRequest
            })
        }),
        manualVoucherGet: build.query<ManualVoucherGetApiResponse, ManualVoucherGetApiArg>({
            query: queryArg => ({ url: `/api/ManualVoucher/${queryArg.voucherId}` })
        }),
        manualVoucherPut: build.mutation<ManualVoucherPutApiResponse, ManualVoucherPutApiArg>({
            query: queryArg => ({
                url: `/api/ManualVoucher/${queryArg.id}`,
                method: 'PUT',
                body: queryArg.putManualVoucherRequest
            })
        }),
        manualVoucherSetStatus: build.mutation<
            ManualVoucherSetStatusApiResponse,
            ManualVoucherSetStatusApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucher/setstatus`,
                method: 'POST',
                params: { voucherId: queryArg.voucherId, status: queryArg.status }
            })
        }),
        manualVoucherGetVoucherNumberFromM3: build.query<
            ManualVoucherGetVoucherNumberFromM3ApiResponse,
            ManualVoucherGetVoucherNumberFromM3ApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucher/GetVoucherNumber`,
                params: { voucherId: queryArg.voucherId }
            })
        }),
        manualVoucherGetCorrectedVoucher: build.mutation<
            ManualVoucherGetCorrectedVoucherApiResponse,
            ManualVoucherGetCorrectedVoucherApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucher/correct`,
                method: 'POST',
                params: { voucherId: queryArg.voucherId }
            })
        }),
        manualVoucherGetVouchers: build.query<
            ManualVoucherGetVouchersApiResponse,
            ManualVoucherGetVouchersApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucher`,
                params: {
                    status: queryArg.status,
                    month: queryArg.month,
                    SearchString: queryArg.searchString,
                    PageIndex: queryArg.pageIndex,
                    PageSize: queryArg.pageSize,
                    SortColumn: queryArg.sortColumn,
                    SortOrder: queryArg.sortOrder
                }
            })
        }),
        manualVoucherCreate: build.mutation<ManualVoucherCreateApiResponse, ManualVoucherCreateApiArg>({
            query: queryArg => ({
                url: `/api/ManualVoucher`,
                method: 'POST',
                body: queryArg.createVoucherRequest
            })
        }),
        manualVoucherDelete: build.mutation<ManualVoucherDeleteApiResponse, ManualVoucherDeleteApiArg>({
            query: queryArg => ({
                url: `/api/ManualVoucher`,
                method: 'DELETE',
                params: { voucherId: queryArg.voucherId }
            })
        }),
        manualVoucherLinesPost: build.mutation<
            ManualVoucherLinesPostApiResponse,
            ManualVoucherLinesPostApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucherLines/${queryArg.voucherId}/lines`,
                method: 'POST',
                body: queryArg.body
            })
        }),
        manualVoucherLinesPut: build.mutation<ManualVoucherLinesPutApiResponse, ManualVoucherLinesPutApiArg>({
            query: queryArg => ({
                url: `/api/manualvoucher/${queryArg.voucherId}/lines`,
                method: 'PUT',
                body: queryArg.body
            })
        }),
        manualVoucherLinesGet: build.query<ManualVoucherLinesGetApiResponse, ManualVoucherLinesGetApiArg>({
            query: queryArg => ({ url: `/api/ManualVoucherLines`, params: { voucherId: queryArg.voucherId } })
        }),
        manualVoucherLinesDelete: build.mutation<
            ManualVoucherLinesDeleteApiResponse,
            ManualVoucherLinesDeleteApiArg
        >({
            query: queryArg => ({
                url: `/api/ManualVoucherLines`,
                method: 'DELETE',
                params: { manualVoucherLineId: queryArg.manualVoucherLineId }
            })
        }),
        divisionsGetAll: build.query<DivisionsGetAllApiResponse, DivisionsGetAllApiArg>({
            query: () => ({ url: `/api/Divisions` })
        }),
        divisionsGet: build.query<DivisionsGetApiResponse, DivisionsGetApiArg>({
            query: queryArg => ({ url: `/api/Divisions/${queryArg.divisionCode}` })
        }),
        currenciesGet: build.query<CurrenciesGetApiResponse, CurrenciesGetApiArg>({
            query: queryArg => ({ url: `/api/Currencies`, params: { division: queryArg.division } })
        }),
        attachmentDeleteAttachment: build.mutation<
            AttachmentDeleteAttachmentApiResponse,
            AttachmentDeleteAttachmentApiArg
        >({
            query: queryArg => ({
                url: `/api/Attachment`,
                method: 'DELETE',
                params: { voucherId: queryArg.voucherId, filename: queryArg.filename }
            })
        }),
        attachmentGet: build.query<AttachmentGetApiResponse, AttachmentGetApiArg>({
            query: queryArg => ({ url: `/api/voucher/${queryArg.voucherId}/attachments` })
        }),
        activityLogSearch: build.query<ActivityLogSearchApiResponse, ActivityLogSearchApiArg>({
            query: queryArg => ({
                url: `/api/activitylogs`,
                params: {
                    SearchString: queryArg.searchString,
                    PageIndex: queryArg.pageIndex,
                    PageSize: queryArg.pageSize,
                    SortColumn: queryArg.sortColumn,
                    SortOrder: queryArg.sortOrder,
                    fromDate: queryArg.fromDate,
                    toDate: queryArg.toDate
                }
            })
        }),
        activityLogGetLoggableActionTypes: build.query<
            ActivityLogGetLoggableActionTypesApiResponse,
            ActivityLogGetLoggableActionTypesApiArg
        >({
            query: () => ({ url: `/api/activitylogs/types` })
        }),
        accountingResetCache: build.mutation<AccountingResetCacheApiResponse, AccountingResetCacheApiArg>({
            query: queryArg => ({
                url: `/api/Accounting`,
                method: 'POST',
                params: { cacheType: queryArg.cacheType }
            })
        }),
        accountingResetValidationRules: build.mutation<
            AccountingResetValidationRulesApiResponse,
            AccountingResetValidationRulesApiArg
        >({
            query: () => ({ url: `/api/Accounting/reset/validationRanges`, method: 'POST' })
        }),
        accountingResetDimensions: build.mutation<
            AccountingResetDimensionsApiResponse,
            AccountingResetDimensionsApiArg
        >({
            query: () => ({ url: `/api/Accounting/reset/dimensions`, method: 'POST' })
        }),
        accountingSearchAccounts: build.query<
            AccountingSearchAccountsApiResponse,
            AccountingSearchAccountsApiArg
        >({
            query: queryArg => ({
                url: `/api/Accounting/accounts`,
                params: { query: queryArg.query, division: queryArg.division }
            })
        }),
        accountingGetDimensions: build.query<
            AccountingGetDimensionsApiResponse,
            AccountingGetDimensionsApiArg
        >({
            query: queryArg => ({
                url: `/api/Accounting/dimensions`,
                params: { division: queryArg.division, dimension: queryArg.dimension, query: queryArg.query }
            })
        }),
        accountingGetVatCodes: build.query<AccountingGetVatCodesApiResponse, AccountingGetVatCodesApiArg>({
            query: queryArg => ({ url: `/api/Accounting/vatcodes`, params: { division: queryArg.division } })
        }),
        accountingGetAccountValidationRules: build.query<
            AccountingGetAccountValidationRulesApiResponse,
            AccountingGetAccountValidationRulesApiArg
        >({
            query: queryArg => ({
                url: `/api/Accounting/validationrules`,
                params: { accountId: queryArg.accountId, division: queryArg.division }
            })
        })
    }),
    overrideExisting: false
});
export { injectedRtkApi as generatedApi };
export type UserSettingsGetAllApiResponse = /** status 200  */ UserSettingsDto[];
export type UserSettingsGetAllApiArg = void;
export type UserSettingsGetApiResponse = /** status 200  */ UserSettingsDto;
export type UserSettingsGetApiArg = void;
export type UserSettingsPutApiResponse = unknown;
export type UserSettingsPutApiArg = {
    username: string | null;
    userSettingsDto: UserSettingsDto;
};
export type UserGetAllApiResponse = /** status 200  */ SimpleUserDto[];
export type UserGetAllApiArg = void;
export type UserGetProfileApiResponse = /** status 200  */ UserProfile;
export type UserGetProfileApiArg = void;
export type OrganizationLevelGetAllApiResponse = /** status 200  */ UserOrganizationLevelDto[];
export type OrganizationLevelGetAllApiArg = void;
export type OrganizationLevelGetApiResponse = /** status 200  */ UserOrganizationLevelDto | null;
export type OrganizationLevelGetApiArg = {
    email?: string | null;
};
export type OrganizationLevelUpdateApiResponse = unknown;
export type OrganizationLevelUpdateApiArg = {
    email?: string | null;
    organizationLevel?: number;
};
export type OrganizationLevelDeleteApiResponse = unknown;
export type OrganizationLevelDeleteApiArg = {
    email?: string | null;
};
export type SettingsGetAppSettingsApiResponse = /** status 200  */ AppSettingsDto;
export type SettingsGetAppSettingsApiArg = void;
export type RecipientsGetEligebleRecipientsApiResponse = /** status 200  */ SimpleUserDto[];
export type RecipientsGetEligebleRecipientsApiArg = void;
export type ManualVoucherCopyVoucherApiResponse = /** status 200  */ number;
export type ManualVoucherCopyVoucherApiArg = {
    copyVoucherRequest: CopyVoucherRequest;
};
export type ManualVoucherShareApiResponse = unknown;
export type ManualVoucherShareApiArg = {
    shareVoucherRequest: ShareVoucherRequest;
};
export type ManualVoucherGetApiResponse = /** status 200  */ ManualVoucherDto;
export type ManualVoucherGetApiArg = {
    voucherId: number;
};
export type ManualVoucherPutApiResponse = unknown;
export type ManualVoucherPutApiArg = {
    id: number;
    putManualVoucherRequest: PutManualVoucherRequest;
};
export type ManualVoucherSetStatusApiResponse = /** status 200  */ ResultDto;
export type ManualVoucherSetStatusApiArg = {
    voucherId?: number;
    status?: number;
};
export type ManualVoucherGetVoucherNumberFromM3ApiResponse = unknown;
export type ManualVoucherGetVoucherNumberFromM3ApiArg = {
    voucherId?: number;
};
export type ManualVoucherGetCorrectedVoucherApiResponse = /** status 200  */ ResultDto;
export type ManualVoucherGetCorrectedVoucherApiArg = {
    voucherId?: number;
};
export type ManualVoucherGetVouchersApiResponse = /** status 200  */ PagedResultOfManualVoucherDto;
export type ManualVoucherGetVouchersApiArg = {
    status?: number;
    month?: string | null;
    searchString?: string | null;
    pageIndex?: number | null;
    pageSize?: number;
    sortColumn?: string | null;
    sortOrder?: string | null;
};
export type ManualVoucherCreateApiResponse = /** status 200  */ number;
export type ManualVoucherCreateApiArg = {
    createVoucherRequest: CreateVoucherRequest;
};
export type ManualVoucherDeleteApiResponse = unknown;
export type ManualVoucherDeleteApiArg = {
    voucherId?: number;
};
export type ManualVoucherLinesPostApiResponse = unknown;
export type ManualVoucherLinesPostApiArg = {
    voucherId: number;
    body: ManualVoucherLineDto[];
};
export type ManualVoucherLinesPutApiResponse = /** status 200  */ ManualVoucherPutResult;
export type ManualVoucherLinesPutApiArg = {
    voucherId: number;
    body: ManualVoucherLineDto[];
};
export type ManualVoucherLinesGetApiResponse = /** status 200  */ ManualVoucherLineDto[];
export type ManualVoucherLinesGetApiArg = {
    voucherId?: number;
};
export type ManualVoucherLinesDeleteApiResponse = unknown;
export type ManualVoucherLinesDeleteApiArg = {
    manualVoucherLineId?: number;
};
export type DivisionsGetAllApiResponse = /** status 200  */ SimpleDivisionDto[];
export type DivisionsGetAllApiArg = void;
export type DivisionsGetApiResponse = /** status 200  */ DivisionDto;
export type DivisionsGetApiArg = {
    divisionCode: string | null;
};
export type CurrenciesGetApiResponse = /** status 200  */ CurrencyDto[];
export type CurrenciesGetApiArg = {
    division?: string | null;
};
export type AttachmentDeleteAttachmentApiResponse = unknown;
export type AttachmentDeleteAttachmentApiArg = {
    voucherId?: number;
    filename?: string | null;
};
export type AttachmentGetApiResponse = /** status 200  */ string[];
export type AttachmentGetApiArg = {
    voucherId: number;
};
export type ActivityLogSearchApiResponse = /** status 200  */ PagedResultOfActivityLogDto;
export type ActivityLogSearchApiArg = {
    searchString?: string | null;
    pageIndex?: number | null;
    pageSize?: number;
    sortColumn?: string | null;
    sortOrder?: string | null;
    fromDate?: string | null;
    toDate?: string | null;
};
export type ActivityLogGetLoggableActionTypesApiResponse = /** status 200  */ string[];
export type ActivityLogGetLoggableActionTypesApiArg = void;
export type AccountingResetCacheApiResponse = unknown;
export type AccountingResetCacheApiArg = {
    cacheType?: number;
};
export type AccountingResetValidationRulesApiResponse = unknown;
export type AccountingResetValidationRulesApiArg = void;
export type AccountingResetDimensionsApiResponse = unknown;
export type AccountingResetDimensionsApiArg = void;
export type AccountingSearchAccountsApiResponse = /** status 200  */ DimensionSelectDto[];
export type AccountingSearchAccountsApiArg = {
    query?: string | null;
    division?: string | null;
};
export type AccountingGetDimensionsApiResponse = /** status 200  */ DimensionSelectDto[];
export type AccountingGetDimensionsApiArg = {
    division?: string | null;
    dimension?: number;
    query?: string | null;
};
export type AccountingGetVatCodesApiResponse = /** status 200  */ VatCodeDto[];
export type AccountingGetVatCodesApiArg = {
    division?: string | null;
};
export type AccountingGetAccountValidationRulesApiResponse = /** status 200  */ AccountValidationDto;
export type AccountingGetAccountValidationRulesApiArg = {
    accountId?: string | null;
    division?: string | null;
};
export type UserSettingsDto = {
    username?: string;
    defaultDivision?: string;
    preferredLanguage?: string;
};
export type SimpleUserDto = {
    username?: string;
    displayName?: string;
};
export type UserProfile = {
    base64Photo: string;
};
export type UserOrganizationLevelDto = {
    email: string;
    organizationLevel: number;
};
export type AppSettingsDto = {
    applicationVersion: string;
};
export type CopyVoucherType = 0 | 1 | 2;
export type CopyVoucherRequest = {
    voucherId: number;
    accountingDate: string;
    copyVoucherType: CopyVoucherType;
};
export type ShareVoucherRequest = {
    voucherId: number;
    targetEmail: string;
    comment?: string | null;
};
export type ManualVoucherDto = {
    isImported?: boolean;
    voucherId?: number | null;
    status?: number;
    createdBy?: string;
    division?: string;
    date?: string;
    voucherNumber?: string;
    accountDate: string;
    approver?: string | null;
    text: string;
    uploadedFiles?: string[] | null;
    totalAmount?: number;
};
export type PutManualVoucherRequest = {
    manualVoucherId?: number;
    accountDate: string;
    text: string;
    division: string;
};
export type ResultDto = {
    isSuccess?: boolean;
    statusMessage?: string | null;
};
export type PagedResultOfManualVoucherDto = {
    rows: ManualVoucherDto[];
    totalCount: number;
    totalUnfilteredCount: number;
};
export type CreateVoucherRequest = {
    division?: string;
    accountDate: string;
    text: string;
};
export type ManualVoucherLineDto = {
    voucherLineId?: number | null;
    text?: string;
    account?: string;
    debitAmount?: number | null;
    creditAmount?: number | null;
    costCenter?: string;
    optional1?: string;
    lotNumber?: string;
    optional2?: string;
    accountingRule?: string;
    counterPart?: string;
    foreignCurrency?: string;
    currencyExchangeRate?: number | null;
    voucherLineNumber?: number;
    manualVoucherId?: number;
    vatCode?: string;
};
export type ValidationResult = {
    memberNames?: string[];
    errorMessage?: string | null;
};
export type VoucherLineValidationResult = {
    lineNumber?: number;
    errors?: ValidationResult[];
};
export type ManualVoucherPutResult = {
    isSuccess?: boolean;
    validationErrors?: VoucherLineValidationResult[];
};
export type SimpleDivisionDto = {
    code?: string;
    name?: string;
};
export type DivisionDto = {
    company?: string;
    divisionCode?: string;
    companyName?: string;
    divisionName?: string;
    localCurrency?: string;
};
export type CurrencyDto = {
    currencyCode?: string;
    currencyName?: string;
    exchangeRate?: number;
    rateDate?: string;
};
export type ActivityLogDto = {
    id: number;
    type: string;
    action: string;
    createdBy: string;
    createdDate: string;
    logData: string;
};
export type PagedResultOfActivityLogDto = {
    rows: ActivityLogDto[];
    totalCount: number;
    totalUnfilteredCount: number;
};
export type DimensionSelectDto = {
    value?: string;
    text?: string;
};
export type VatCodeDto = {
    code?: string;
    name?: string;
};
export type AccountValidationDto = {
    accountId?: string;
    isLocked?: boolean;
    isTextRequired?: boolean;
    costCenterValidationRule?: number;
    optional1ValidationRule?: number;
    optional2ValidationRule?: number;
    counterPartValidationRule?: number;
    accountingRuleValidationRule?: number;
    lotNrValidationRule?: number;
};
