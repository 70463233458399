import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../features/ui/redux/uiSlice';

const getErrorMessage = (payload: any) => {
    if (payload?.data?.message) {
        return payload.data?.message;
    }

    if (payload?.error) {
        return payload.error;
    }
    if (payload?.data?.title) {
        return payload.data.title;
    }
    return 'An unexpected error occurred.';
};

/**
 * Middleware that intercepts errors from rtk-query and dispatches snackbar error actions
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => next => action => {
    const errorMessage = getErrorMessage(action.payload);

    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    // Validation errors are handled in the components
    if (isRejectedWithValue(action) && errorMessage !== 'One or more validation errors occurred.') {
        api.dispatch(
            enqueueSnackbar({
                message: getErrorMessage(action.payload),
                severity: 'error',
                autoHideDuration: 30000
            })
        );
    }

    return next(action);
};
