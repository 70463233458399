import { createSlice } from '@reduxjs/toolkit';
import { PagedSearch } from '../../../common/pagination/paginationTypes';
import { PagedResultOfActivityLogDto } from '../../../store/api/generatedApi';

const namespaces = {
    activityLogs: 'activityLogs'
};

const activityLogsSlice = createSlice({
    name: namespaces.activityLogs,
    initialState: {
        pagination: {
            pageIndex: 0,
            pageSize: 50,
            totalCount: 0,
            totalUnfilteredCount: 0,
            sortColumn: 'id',
            sortOrder: 'ASC'
        } as PagedSearch & Omit<PagedResultOfActivityLogDto, 'rows'>
    },
    reducers: {}
});

export default activityLogsSlice.reducer;
