import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import ui from '../features/ui/redux/uiSlice';
import user from '../features/user/redux/userSlice';
import voucher from '../features/vouchers/redux/vouchersSlice';
import activityLog from '../features/activitylog/redux/activityLogSlice';
import { manualVoucherApi } from './api/manualVoucherApi';
import { listenerMiddleware } from './listenerMiddleware';
import { rtkQueryErrorLogger } from './rtkQueryErrorMiddleware';

const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
    configureStore({
        reducer: {
            [manualVoucherApi.reducerPath]: manualVoucherApi.reducer,
            user,
            ui,
            voucher,
            activityLog
        },
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware()
                .prepend(listenerMiddleware.middleware)
                .concat(manualVoucherApi.middleware)
                .concat(rtkQueryErrorLogger),
        ...options
    });

export const store = createStore();

// enable listener behavior for the store
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
