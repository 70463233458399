import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { acquireAccessTokenSilent, msalInstance } from '../../common/auth/auth';

// Initializes an empty api which swagger will use to add endpoints to in generatedApi.ts
export const emptyApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_HOSTNAME!,
        prepareHeaders: headers => {
            return acquireAccessTokenSilent()
                .then(function (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                    return headers;
                })
                .catch(function (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
                        const accounts = msalInstance.getAllAccounts();

                        if (!activeAccount && accounts.length === 0) {
                            // User is not signed in. Throw error or wait for user to login.
                            // Do not attempt to log a user in outside of the context of MsalProvider
                            throw new Error('User is not authenticated!');
                        }

                        const request = {
                            scopes: [process.env.REACT_APP_API_SCOPE!],
                            account: activeAccount || accounts[0]
                        };
                        // call acquireTokenPopup in case of InteractionRequiredAuthError failure
                        msalInstance
                            .acquireTokenRedirect(request)
                            .then(function (token) {
                                headers.set('Authorization', `Bearer ${token}`);
                            })
                            .catch(function (error) {});
                    }
                    return headers;
                });
        }
    }),
    // global configuration for the api
    refetchOnReconnect: true,
    endpoints: builder => ({
        attachmentUploadAttachment: builder.mutation<string[], FormData>({
            query: queryArg => ({ url: `/api/Attachment`, method: 'POST', body: queryArg })
        }),
        importVoucherImport: builder.mutation<number, FormData>({
            query: queryArg => ({ url: `/api/import/importVoucher`, method: 'POST', body: queryArg })
        })
    })
});
