import { generatedApi } from './generatedApi';
const Tags = {
    voucher: 'Voucher',
    attachments: 'Attachments',
    userSettings: 'UserSettings',
    activityLogs: 'ActivityLogs',
    activityLogTypes: 'ActivityLogTypes',
    organizationLevels: 'OrganizationLevels'
};

// Special id for invalidating the whole list instead of a specific list entry.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ListId = 'LIST';

// Enhances the generated API with stuff that is not available in the generated api.
// An example is for adding tags which is used for cache invalidation.
export const manualVoucherApi = generatedApi.enhanceEndpoints({
    addTagTypes: [Tags.voucher, Tags.attachments],
    endpoints: {
        // Enhance the generated endpoints here
        manualVoucherGetVouchers: {
            keepUnusedDataFor: 30,
            providesTags: result =>
                result
                    ? [
                          ...(result.rows ?? []).map(
                              ({ voucherId }) => ({ type: Tags.voucher, voucherId } as const)
                          ),
                          { type: Tags.voucher, id: ListId }
                      ]
                    : [{ type: Tags.voucher, id: ListId }]
        },
        manualVoucherGet: {
            keepUnusedDataFor: 30,
            providesTags: result => [
                { type: Tags.voucher, id: ListId },
                { type: Tags.voucher, voucherId: result?.voucherId! }
            ]
        },
        manualVoucherCreate: {
            invalidatesTags: [{ type: Tags.voucher, id: ListId }]
        },
        manualVoucherPut: {
            invalidatesTags: [{ type: Tags.voucher, id: ListId }]
        },
        manualVoucherDelete: {
            invalidatesTags: (result, error, { voucherId }) => [{ type: Tags.voucher, id: voucherId }]
        },
        manualVoucherGetCorrectedVoucher: {
            invalidatesTags: (result, error, { voucherId }) => [{ type: Tags.voucher, id: voucherId }]
        },
        attachmentGet: {
            providesTags: [{ type: Tags.attachments, id: ListId }]
        },
        attachmentDeleteAttachment: {
            invalidatesTags: [
                { type: Tags.attachments, id: ListId },
                { type: Tags.voucher, id: ListId }
            ]
        },
        attachmentUploadAttachment: {
            invalidatesTags: [
                { type: Tags.attachments, id: ListId },
                { type: Tags.voucher, id: ListId }
            ]
        },
        manualVoucherSetStatus: {
            invalidatesTags: [{ type: Tags.voucher, id: ListId }]
        },
        importVoucherImport: {
            invalidatesTags: [{ type: Tags.voucher, id: ListId }]
        },
        userSettingsGet: {
            providesTags: [{ type: Tags.userSettings, id: ListId }],
            keepUnusedDataFor: 0
        },
        userSettingsPut: {
            invalidatesTags: [{ type: Tags.attachments, id: ListId }]
        },
        activityLogGetLoggableActionTypes: {
            providesTags: [{ type: Tags.activityLogTypes, id: ListId }]
        },
        activityLogSearch: {
            providesTags: [{ type: Tags.activityLogs, id: ListId }]
        },
        organizationLevelGetAll: {
            providesTags: [{ type: Tags.organizationLevels, id: ListId }]
        },
        organizationLevelUpdate: {
            invalidatesTags: [{ type: Tags.organizationLevels, id: ListId }]
        },
        organizationLevelDelete: {
            invalidatesTags: [{ type: Tags.organizationLevels, id: ListId }]
        }
    }
});

// Export all endpoint hooks
export const {
    useUserGetProfileQuery,
    useSettingsGetAppSettingsQuery,
    useManualVoucherGetVouchersQuery,
    useManualVoucherCreateMutation,
    useManualVoucherGetQuery,
    useManualVoucherPutMutation,
    useManualVoucherDeleteMutation,
    useManualVoucherGetCorrectedVoucherMutation,
    useDivisionsGetQuery,
    useDivisionsGetAllQuery,
    useManualVoucherLinesGetQuery,
    useCurrenciesGetQuery,
    useAccountingSearchAccountsQuery,
    useAccountingGetDimensionsQuery,
    useAccountingGetVatCodesQuery,
    useImportVoucherImportMutation,
    useLazyUserSettingsGetQuery,
    useUserSettingsGetQuery,
    useUserSettingsPutMutation,
    useManualVoucherLinesDeleteMutation,
    useManualVoucherLinesPutMutation,
    useLazyAccountingGetAccountValidationRulesQuery,
    useAttachmentGetQuery,
    useAttachmentUploadAttachmentMutation,
    useAttachmentDeleteAttachmentMutation,
    useManualVoucherSetStatusMutation,
    useActivityLogGetLoggableActionTypesQuery,
    useLazyActivityLogSearchQuery,
    useAccountingResetCacheMutation,
    useManualVoucherShareMutation,
    useRecipientsGetEligebleRecipientsQuery,
    useManualVoucherCopyVoucherMutation,
    useAccountingResetValidationRulesMutation,
    useAccountingResetDimensionsMutation,
    useOrganizationLevelGetAllQuery,
    useOrganizationLevelGetQuery,
    useOrganizationLevelUpdateMutation,
    useOrganizationLevelDeleteMutation,
    useUserGetAllQuery
} = manualVoucherApi;
