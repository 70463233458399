import { AccountInfo } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type User = {
    roles: string[];
    account: AccountInfo | null;
};

type UserState = {
    user: User | null;
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: null
    } as UserState,
    reducers: {
        userAuthenticationChanged: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        }
    }
});

export const { userAuthenticationChanged } = userSlice.actions;

export default userSlice.reducer;
